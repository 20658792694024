import { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../util/firebase';
import { CustomToast } from '../Notifications/CustomToast';
import { useAuth } from '../../hooks/useAuth';
import { useForm } from 'react-hook-form';

export const ProductReportForm = ({ product, toggle }) => {
  const [loading, setLoading] = useState(false);

  const { profile } = useAuth();
  const { setValue, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      uid: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
  });

  useEffect(() => {
    if (!profile) return;
    reset(profile);
  }, [profile]);

  const onSubmit = async (data) => {
    if (!data.firstName || !data.lastName || !data.phoneNumber) {
      CustomToast('error', 'Completa todos los campos');
      return;
    }
    try {
      setLoading(true);
      await addDoc(collection(db, 'requestProduct'), {
        url: product?.optimized_url ?? product?.url ?? window.location.href,
        uidUser: profile?.uid ?? '',
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phoneNumber,
        createdAt: Date.now(),
      });
      CustomToast('success', 'Petición enviada correctamente');
      toggle();
    } catch {
      CustomToast('error', 'Ocurrió un error al enviar la petición');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <img
          src={product?.image}
          alt="Foto"
          style={{ width: '200px', height: '200px', objectFit: 'contain' }}
        />
        <p style={{ fontSize: '20px', fontWeight: '700' }}>{product?.title}</p>
      </div>

      <h3>
        Vamos a generar un reporte para brindarte la cotización. Favor de
        actualizar tus datos para poder enviarte el precio y la fecha de entrega
        en menos de 24 horas.
      </h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">Nombre</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('firstName')}
          onChange={(e) => setValue('firstName', e.target.value)}
          placeholder="Tu Nombre"
        />
        <p className="label">Apellidos</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('lastName')}
          onChange={(e) => setValue('lastName', e.target.value)}
          placeholder="Tu apellido"
        />
        <p className="label">Teléfono</p>
        <Input
          type="text"
          className="mainInput"
          defaultValue={getValues('phoneNumber')}
          onChange={(e) => setValue('phoneNumber', e.target.value)}
          placeholder="Tu Número de teléfono"
        />

        <Button type="submit" id="searchProductBtn" className="primaryBtn">
          {loading ? 'Enviando...' : 'Enviar'}
        </Button>
      </form>
    </div>
  );
};
