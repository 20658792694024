import { TAX_CAP_PRICE } from '../contants';

const debugLog = (formula, total, debug) => {
  if (debug) console.debug(`${formula} = ${total.toFixed(2)}`);
};

export const calculateAmazonPrice = (
  price,
  weight = null,
  shipping = 0,
  debug = false,
) => {
  const getMultiplier = (price) => {
    const ranges = [
      { min: 0, max: 3, multiplier: 2.0 },
      { min: 3.01, max: 5, multiplier: 1.7 },
      { min: 5.01, max: 8, multiplier: 1.5 },
      { min: 8.01, max: 10, multiplier: 1.25 },
      { min: 10.01, max: 15, multiplier: 1.25 },
      { min: 15.01, max: 20, multiplier: 1.25 },
      { min: 20.01, max: 30, multiplier: 1.22 },
      { min: 30.01, max: 50, multiplier: 1.22 },
      { min: 50.01, max: 100, multiplier: 1.2 },
      { min: 100.01, max: 200, multiplier: 1.15 },
      { min: 200.01, max: 500, multiplier: 1.1 },
    ];
    const range = ranges.find((r) => price >= r.min && price <= r.max);
    return range ? range.multiplier : 1.07;
  };

  const isCategorySpecial = price >= TAX_CAP_PRICE;
  const commission = isCategorySpecial ? 1.13 : 1;
  const multiplier = getMultiplier(price);
  const total = weight
    ? (price + shipping) * 1.09 * commission + weight * 6.5 * 1.15 + 0.5
    : price * 1.09 * multiplier * commission + 0.5;
  debugLog(
    weight
      ? `(((${price} + ${shipping}) * 1.09) * ${commission}) + (${weight} * 6.5 * 1.15) + 0.5`
      : `((${price} * 1.09 * ${multiplier}) * ${commission}) + 0.5`,
    total,
    debug,
  );
  return parseFloat(total.toFixed(2));
};

export const calculateSheinPrice = (price, debug = false) => {
  const getMultiplier = (price) => {
    const ranges = [
      { min: 0, max: 2, multiplier: 1.95 },
      { min: 2.01, max: 4, multiplier: 1.75 },
      { min: 4.01, max: 10, multiplier: 1.55 },
      { min: 10.01, max: 20, multiplier: 1.45 },
      { min: 20.01, max: 30, multiplier: 1.4 },
    ];
    const range = ranges.find((r) => price >= r.min && price <= r.max);
    return range ? range.multiplier : 1.35;
  };
  const isCategorySpecial = price >= TAX_CAP_PRICE;
  const commission = isCategorySpecial ? 1.13 : 1;
  const multiplier = getMultiplier(price);
  const total = price * multiplier * commission;
  debugLog(`(${price} * ${multiplier}) * ${commission}`, total, debug);
  return parseFloat(total.toFixed(2));
};
