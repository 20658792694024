import { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { calculateAmazonPrice } from '../../../../util/price';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { findHistoryItem } from '../../../../util/array';
import {
  getDeliveryPrice,
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
} from '../../../../util/amazon';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import TextLoader from '../../../Loaders/TextLoader/TextLoader';
import { useNavigate } from 'react-router-dom';
import AmazonService from '../../../../services/amazon.service';
import StarRating from '../../../Reviews/StarRating/StarRating';
import AmazonAddModal from '../../Modals/Amazon/AmazonAddModal';
import {
  DEFAULT_AMAZON_SHIPPING,
  MAX_PRODUCT_PRICE,
  MAX_PRODUCT_WEIGHT,
} from '../../../../contants';

export const AmazonProductCard = ({
  productDefault,
  trips = [],
  drivers = [],
  productHistory = [],
}) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productReportModal, setProductReportModal] = useState(false);
  const [productAddModal, setProductAddModal] = useState(false);

  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!productDefault || product) return;
      try {
        const response = await amazonService.detail(productDefault.asin);
        setProduct({
          ...productDefault,
          ...response,
        });
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [productDefault]);

  const colorOptions = useMemo(() => {
    if (!product) return null;
    return product?.customization_options?.color;
  }, [product]);

  const sizeOptions = useMemo(() => {
    if (!product) return null;
    return product?.customization_options?.size;
  }, [product]);

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    let price =
      parseFloat(product?.price_string?.replace(/[^\d.-]+/g, '')) || 0;
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let weightRecord = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    let weightAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = getDeliveryPrice(product?.shipping_info);
    let weightDimension = getWeigthFromProductDimensions(product);
    if (weightRecord) {
      if (parseFloat(weightRecord) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightRecord) < 0.25) weightRecord = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightRecord) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightAmazon) {
      if (parseFloat(weightAmazon) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightAmazon) < 0.25) weightAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDimension) {
      if (parseFloat(weightDimension) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightDimension) < 0.25) weightDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return calculateAmazonPrice(price, null, shippingAmazon ?? 0);
  }, [product, productHistory]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    let price = !isNaN(
      parseFloat(product?.previous_price?.replace(/[^\d.-]+/g, '')),
    )
      ? parseFloat(product?.previous_price?.replace(/[^\d.-]+/g, ''))
      : parseFloat(product?.price?.replace(/[^\d.-]+/g, '')) || 0;
    if (!price) return null;
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? null;
    let weightAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = getDeliveryPrice(product?.shipping_info);
    let weightDimension = getWeigthFromProductDimensions(product);
    if (historyWeight) {
      if (parseFloat(historyWeight) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightAmazon) {
      if (parseFloat(weightAmazon) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightAmazon) < 0.25) weightAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDimension) {
      if (parseFloat(weightDimension) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightDimension) < 0.25) weightDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return calculateAmazonPrice(price, null, shippingAmazon ?? 0);
  }, [product, productHistory]);

  const estimateDeliveryDate = useMemo(() => {
    const now = new Date();
    const dateKey =
      product?.prime_shipping_info ?? product?.shipping_info ?? null;
    const dateAmazon = dateKey ? parseDate(dateKey) : null;
    if (!dateAmazon) {
      now.setDate(now.getDate() + DEFAULT_AMAZON_SHIPPING);
      return now;
    }
    const filterTrips = trips.filter(
      (trip) => new Date(trip?.date) > dateAmazon,
    );
    const dateOptimization = filterTrips.find((trip) => {
      const driver = drivers.find((user) => user?.uid === trip.travelerUid);
      if (!driver) return false;
      const dayBeforeTravel = parseInt(driver.dayBeforeTravel) ?? 3;
      const travelDate = new Date(trip.date);
      travelDate.setDate(travelDate.getDate() - dayBeforeTravel);
      return dateAmazon < travelDate;
    });
    if (!dateOptimization) {
      const fallbackDate = new Date(dateAmazon);
      fallbackDate.setDate(fallbackDate.getDate() + DEFAULT_AMAZON_SHIPPING);
      return fallbackDate;
    }
    const dateTravel = new Date(dateOptimization.date);
    const daysAfterTravel =
      parseInt(
        drivers.find((user) => user?.uid === dateOptimization.travelerUid)
          ?.dayAfterTravel,
      ) ?? 3;
    dateTravel.setDate(dateTravel.getDate() + daysAfterTravel);
    return dateTravel;
  }, [product, trips, drivers]);

  return (
    <div
      className="product"
      onClick={() => navigate(`/detail/${productDefault.asin}`)}
    >
      <div className="productImageContainer">
        <img
          alt="productImage"
          src={productDefault.image}
          className="productImage"
        />
      </div>
      <div className="productContent">
        <p className="productName">{productDefault.title}</p>

        {productDefault?.stars && (
          <div className="rating-container">
            <StarRating
              rating={productDefault?.stars}
              style={{ margin: 0, padding: 0 }}
            />
          </div>
        )}

        {estimateDeliveryDate && (
          <p className="productDeliveryDate">
            Fecha Estimada de entrega:{' '}
            <span className="bold-text">
              {formatDateSpanish(estimateDeliveryDate)}
            </span>
          </p>
        )}
        {loading ? (
          <TextLoader
            style={{
              alignSelf: 'center',
              width: '100%',
              padding: '0.5rem 2rem',
              fontSize: '16px',
            }}
            texts={['Calculando precio todo incluido']}
          />
        ) : (
          <>
            {salePrice ? (
              <>
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
                <p className="priceMessage">(Precio todo incluido)</p>

                <Button
                  className="primaryBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setProductAddModal(true);
                  }}
                >
                  Agregar
                </Button>
              </>
            ) : (
              <Button
                className="primaryBtn"
                style={{ marginTop: '0.5rem' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setProductReportModal(true);
                }}
              >
                Solicitar cotización
              </Button>
            )}
          </>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: product?.optimized_url ?? '',
          title: product?.title ?? '',
          image: product?.image ?? '',
        }}
      />
      <AmazonAddModal
        isOpen={productAddModal}
        toggle={() => setProductAddModal((prev) => !prev)}
        productDefault={product}
        trips={trips}
        drivers={drivers}
        productHistory={productHistory}
        colorOptions={colorOptions}
        sizeOptions={sizeOptions}
        setProductReportModal={setProductReportModal}
      />
    </div>
  );
};
