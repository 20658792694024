export function getAsinFromUrl(url) {
  const match = url?.match(
    /(?:\/(?:dp|gp(?:\/(?:product|aw\/d|slredirect\/p))?|product)\/([\da-z]{10}))/i,
  );
  return match ? match[1] : null;
}

export const calculateWeight = (infoWeigthAmazon) => {
  const infoValue =
    infoWeigthAmazon && typeof infoWeigthAmazon === 'object'
      ? infoWeigthAmazon?.value
      : infoWeigthAmazon;
  const cleanString = (infoValue || '')
    ?.toString()
    ?.replace(/[^\x20-\x7E]/g, '');
  const [weightStr, type] = cleanString?.split(' ') || [];
  let weight = parseFloat(weightStr) || 0;
  const lowerType = type?.toLowerCase() || '';
  switch (true) {
    case lowerType.includes('ounces'):
      weight /= 16;
      break;
    case lowerType.includes('kilograms'):
      weight *= 2.20462;
      break;
    case lowerType.includes('grams'):
      weight /= 453.592;
      break;
    default:
      break;
  }
  return parseFloat(weight.toFixed(2));
};

export const getWeightFromProductDetails = (product) => {
  if (!product) return null;
  const weight =
    Object.entries(product.product_information || {}).find(
      ([key]) =>
        key.toLowerCase().includes('weight') ||
        key.toLowerCase().includes('item weight'),
    )?.[1] || null;
  if (!weight) return null;
  return calculateWeight(weight);
};

export const getWeigthFromProductDimensions = (product) => {
  if (!product) return null;
  const dimension =
    Object.entries(product.product_information || {}).find(
      ([key]) =>
        key.toLowerCase().includes('package dimensions') ||
        key.toLowerCase().includes('product dimensions'),
    )?.[1] || null;
  if (!dimension) return null;
  return calculateWeight(getWeigthItemDimesion(dimension));
};

export function getWeigthItemDimesion(product) {
  if (!product) return null;
  const regex = /(\d+(\.\d+)?)\s*(ounces?|grams?|pounds?|kilograms?)/i;
  const match = product.toLowerCase().match(regex);
  if (match) return match[0];
  return null;
}

export function getDeliveryPrice(shipping_info) {
  if (shipping_info?.toLowerCase().includes('free')) return 0;
  const match = shipping_info?.match(/\$\d+(\.\d{1,2})?/g);
  return match ? Number.parseFloat(match[0].replace(/[^\d.]/g, '')) : 0;
}
