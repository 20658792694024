import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './StickyPopup.css'; // Import the external CSS file

const StickyPopup = ({ title, description, footer }) => {
  const [show, setShow] = useState(
    sessionStorage.getItem('showPopup') === null ? true : false,
  );

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem('showPopup', JSON.stringify(false));
  };

  return (
    show && (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="sticky-popup"
        role="alert"
      >
        <div className="sticky-popup-close">
          <motion.button
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            type="button"
            aria-label="Close"
            onClick={handleClose}
            className="sticky-popup-btn-close"
          >
            ✖
          </motion.button>
        </div>
        <h4 className="sticky-popup-heading">{title}</h4>
        <div className="sticky-popup-description">{description}</div>
        <div className="sticky-popup-footer">{footer}</div>
      </motion.div>
    )
  );
};

export default StickyPopup;
