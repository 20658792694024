import React, { useEffect, useMemo, useState } from 'react';
import PageLoader from '../../../Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../Notifications/CustomToast';
import '../../../../assets/css/ProductDetail.css';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { calculateAmazonPrice } from '../../../../util/price';
import { findHistoryItem } from '../../../../util/array';
import {
  getAsinFromUrl,
  getDeliveryPrice,
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
} from '../../../../util/amazon';
import { cancelAllRequests } from '../../../../util/axios-req';
import ImageViewer from '../../../Images/ImageViewer';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import StarRating from '../../../Reviews/StarRating/StarRating';
import { AmazonProductCard } from '../../Card/Amazon/AmazonProductCard';
import AmazonService from '../../../../services/amazon.service';
import FirebaseService from '../../../../services/firebase.service';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import { limitWords } from '../../../../util/string';
import {
  DEFAULT_AMAZON_SHIPPING,
  MAX_PRODUCT_PRICE,
  MAX_PRODUCT_WEIGHT,
} from '../../../../contants';

export default function AmazonDetail({ asin }) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [travelerSelect, setTravelerSelect] = useState(null);
  const [weightProduct, setWeigthProduct] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [categoryWeights, setCategoryWeights] = useState([]);
  const [productReportModal, setProductReportModal] = useState(false);

  const { profile, cart, setCart } = useAuth();
  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  const colorOptions = useMemo(() => {
    if (!product) return null;
    return product?.customization_options?.color;
  }, [product]);

  const sizeOptions = useMemo(() => {
    if (!product) return null;
    return product?.customization_options?.size;
  }, [product]);

  useEffect(() => {
    const fetchData = async () => {
      if (loading || !asin || product?.asin === asin) return;
      try {
        resetValues();
        setLoading(true);
        const productPromise = searchProduct();
        const driversPromise = searchDrivers();
        const tripsPromise = searchTrips();
        const productHistoryPromise = searchProductHistory();
        const categoryWeightsPromise = searchCategoryWeights();
        await Promise.all([
          productPromise,
          driversPromise,
          tripsPromise,
          productHistoryPromise,
          categoryWeightsPromise,
        ]);
      } catch {
        CustomToast('error', 'Error al buscar productos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [asin]);

  useEffect(() => {
    if (loading || !product) return;
    console.debug(product);
    if (colorOptions?.length > 0) {
      const defaultColor = colorOptions.find(
        (item) =>
          item?.is_selected === true ||
          item?.asin === product.asin ||
          getAsinFromUrl(item?.url ?? '') === product.asin,
      );
      if (defaultColor)
        setColor({
          value: product.asin,
          label: defaultColor.value,
        });
    }
    if (sizeOptions?.length > 0) {
      const defaultSize = sizeOptions.find(
        (item) =>
          item?.is_selected === true ||
          item?.asin === product.asin ||
          getAsinFromUrl(item?.url ?? '') === product.asin,
      );
      if (defaultSize)
        setSize({
          value: product.asin,
          label: defaultSize.value,
        });
    }
  }, [loading, product, colorOptions, sizeOptions]);

  useEffect(() => {
    if (!color || color?.value === product?.asin) return;
    navigate(`/detail/${color.value}`, { replace: true });
  }, [color]);

  useEffect(() => {
    if (!size || size?.value === product?.asin) return;
    navigate(`/detail/${size.value}`, { replace: true });
  }, [size]);

  useEffect(() => {
    if (!product || recommendations.length > 0) return;
    searchRecomendations();
  }, [product, recommendations]);

  const resetValues = async () => {
    cancelAllRequests();
    setProduct(null);
    setRecommendations([]);
    setColor(null);
    setSize(null);
  };

  const searchProduct = async () => {
    try {
      const searchResponse = await amazonService.search(asin);
      const detailResponse = await amazonService.detail(asin);
      setProduct(
        {
          ...(searchResponse.results.find((item) => item.asin === asin) ?? {
            asin,
            image:
              detailResponse?.images?.length > 0
                ? detailResponse.images[0]
                : null,
          }),
          ...detailResponse,
        } ?? null,
      );
    } catch {
      CustomToast('error', 'Error al buscar producto');
    }
  };

  const searchRecomendations = async () => {
    if (!product?.title) return;
    try {
      const query = limitWords(product.title, 5);
      const response = await amazonService.search(query);
      if (response.results.length > 0)
        setRecommendations(response.results.slice(0, 10));
    } catch {
      CustomToast('error', 'Error al buscar recomendaciones');
    }
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      if (response.length > 0) setDrivers(response);
    } catch {
      CustomToast('error', 'Error al buscar conductores');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      if (response.length > 0) setTrips(response);
    } catch {
      CustomToast('error', 'Error al buscar viajes');
    }
  };

  const searchCategoryWeights = async () => {
    try {
      const response = await firebaseService.findActiveCategoryWeights();
      if (response.length > 0) setCategoryWeights(response);
    } catch {
      CustomToast('error', 'Error al buscar pesos de categorías');
    }
  };

  const searchProductHistory = async () => {
    try {
      const response = await firebaseService.findHistoryProducts();
      if (response.length > 0) setProductHistory(response);
    } catch {
      CustomToast('error', 'Error al buscar historial de productos');
    }
  };

  const handleAddToCart = async () => {
    if (!product) return;
    if (colorOptions && colorOptions?.length > 0 && !color) {
      CustomToast('warning', 'Por favor selecciona un color');
      return;
    }
    if (sizeOptions && sizeOptions?.length > 0 && !size) {
      CustomToast('warning', 'Por favor selecciona una talla/capacidad/tamaño');
      return;
    }
    try {
      setLoading(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        id: crypto.randomUUID(),
        priceCalculate: salePrice,
        weigthAmazon: weightProduct ?? null,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect,
        color,
        size,
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile?.uid)
        await firebaseService.updateCart(profile?.uid, { ...cart, items });
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async () => {
    handleAddToCart();
    navigate('/checkout');
  };

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    let price =
      parseFloat(product?.price_string?.replace(/[^\d.-]+/g, '')) || 0;
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let weightRecord = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price ?? null;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    let weightAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = getDeliveryPrice(product?.shipping_info);
    let weightDimension = getWeigthFromProductDimensions(product);
    console.debug({
      weightRecord,
      weightAmazon,
      weightDimension,
    });
    if (weightRecord) {
      if (parseFloat(weightRecord) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightRecord) < 0.25) weightRecord = 0.25;
      setWeigthProduct(parseFloat(weightRecord));
      return calculateAmazonPrice(
        price,
        parseFloat(weightRecord) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    if (weightAmazon) {
      if (parseFloat(weightAmazon) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightAmazon) < 0.25) weightAmazon = 0.25;
      setWeigthProduct(parseFloat(weightAmazon));
      return calculateAmazonPrice(
        price,
        parseFloat(weightAmazon) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    if (weightDimension) {
      if (parseFloat(weightDimension) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightDimension) < 0.25) weightDimension = 0.25;
      setWeigthProduct(parseFloat(weightDimension));
      return calculateAmazonPrice(
        price,
        parseFloat(weightDimension) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    return calculateAmazonPrice(price, null, shippingAmazon ?? 0, true);
  }, [product, productHistory, categoryWeights]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    let price = !isNaN(
      parseFloat(product?.previous_price?.replace(/[^\d.-]+/g, '')),
    )
      ? parseFloat(product?.previous_price?.replace(/[^\d.-]+/g, ''))
      : parseFloat(product?.price?.replace(/[^\d.-]+/g, '')) || 0;
    if (!price) return null;
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let weightRecord = historyItem?.currentWeigth ?? null;
    let weightAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = getDeliveryPrice(product?.shipping_info);
    let weightDimension = getWeigthFromProductDimensions(product);
    if (weightRecord) {
      if (parseFloat(weightRecord) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightRecord) < 0.25) weightRecord = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightRecord) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightAmazon) {
      if (parseFloat(weightAmazon) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightAmazon) < 0.25) weightAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDimension) {
      if (parseFloat(weightDimension) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightDimension) < 0.25) weightDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return calculateAmazonPrice(price, null, shippingAmazon ?? 0);
  }, [product, productHistory, categoryWeights]);

  const estimateDeliveryDate = useMemo(() => {
    const now = new Date();
    const dateKey =
      product?.prime_shipping_info ?? product?.shipping_info ?? null;
    const dateAmazon = dateKey ? parseDate(dateKey) : null;
    if (!dateAmazon) {
      now.setDate(now.getDate() + DEFAULT_AMAZON_SHIPPING);
      return now;
    }
    const filterTrips = trips.filter(
      (trip) => new Date(trip?.date) > dateAmazon,
    );
    const dateOptimization = filterTrips.find((trip) => {
      const driver = drivers.find((user) => user?.uid === trip.travelerUid);
      if (!driver) return false;
      const dayBeforeTravel = parseInt(driver.dayBeforeTravel) ?? 3;
      const travelDate = new Date(trip.date);
      travelDate.setDate(travelDate.getDate() - dayBeforeTravel);
      return dateAmazon < travelDate;
    });
    if (!dateOptimization) {
      const fallbackDate = new Date(dateAmazon);
      fallbackDate.setDate(fallbackDate.getDate() + DEFAULT_AMAZON_SHIPPING);
      return fallbackDate;
    }
    setTravelerSelect(dateOptimization);
    const dateTravel = new Date(dateOptimization.date);
    const daysAfterTravel =
      parseInt(
        drivers.find((user) => user?.uid === dateOptimization.travelerUid)
          ?.dayAfterTravel,
      ) ?? 3;
    dateTravel.setDate(dateTravel.getDate() + daysAfterTravel);
    return dateTravel;
  }, [product, trips, drivers]);

  const discountPercentage = useMemo(() => {
    if (!salePrice || !originalPrice) return null;
    return ((1 - salePrice / originalPrice) * 100).toFixed(2);
  }, [salePrice, originalPrice]);

  if (loading || !product) return <PageLoader />;

  return (
    <div className="detail-container">
      <div className="top-section">
        {product?.images?.length > 0 && (
          <div className="image-gallery">
            <ImageViewer
              images={product?.images.slice(0, 6).map((item, index) => {
                return {
                  src: item,
                  description: index,
                };
              })}
            />
          </div>
        )}
        <div className="product-details">
          <h1 className="product-title">{product?.title}</h1>
          {product?.stars && (
            <div className="section-container">
              <StarRating rating={product.stars} />
            </div>
          )}
          <div className="price-container">
            <div className="price-section">
              {discountPercentage > 0 && (
                <span className="discount-percentage">
                  -{discountPercentage}%
                </span>
              )}
              {salePrice && (
                <span className="discount-price">${salePrice}</span>
              )}
            </div>
            {originalPrice && originalPrice !== salePrice && (
              <div className="price-section">
                <span className="original-price-title">Precio original: </span>
                <span className="original-price">${originalPrice}</span>
              </div>
            )}
          </div>

          {colorOptions && colorOptions?.length > 0 && (
            <div className="section-container">
              <p className="section-title">Colores:</p>
              <CustomSelect
                placeholder="Selecciona un color"
                value={color}
                setValue={setColor}
                options={colorOptions.map((item) => {
                  return {
                    value: getAsinFromUrl(item?.url ?? '') ?? item?.asin,
                    label: item.value,
                  };
                })}
              />
            </div>
          )}

          {sizeOptions && sizeOptions?.length > 0 && (
            <div className="section-container">
              <p className="section-title">Tallas:</p>
              <CustomSelect
                placeholder="Selecciona una talla"
                value={size}
                setValue={setSize}
                options={sizeOptions.map((item) => {
                  return {
                    value: getAsinFromUrl(item?.url ?? '') ?? item?.asin,
                    label: item.value,
                  };
                })}
              />
            </div>
          )}

          {product?.description && (
            <div className="section-container">
              <p className="section-title">Sobre este producto:</p>
              <p>{product?.description}</p>
            </div>
          )}
        </div>

        <div className="purchase-options">
          {salePrice ? (
            <>
              <p className="section-title">Comprar nuevo:</p>
              <h2>${salePrice}</h2>
              <p>En existencias</p>
              {estimateDeliveryDate && (
                <p className="delivery-date">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              <div className="purchase-buttons">
                <Button className="primaryBtn" onClick={handleAddToCart}>
                  Agregar a carrito
                </Button>
                <Button className="secondaryBtn" onClick={handlePurchase}>
                  Comprar ahora
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="section-title">Este producto no esta disponible</p>
              <Button
                className="primaryBtn"
                onClick={() => setProductReportModal(true)}
              >
                Solicitar cotizacion
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="bottom-section">
        {product?.product_information &&
          Object.keys(product.product_information).length > 0 && (
            <div className="product-specifications">
              <p className="section-title">Detalles del producto:</p>
              {Object.entries(product.product_information)
                .filter(
                  ([key, value]) => typeof value !== 'object' || value === null,
                )
                .map(([key, value], index) => (
                  <div key={index} className="product-specifications-item">
                    <p>{key}:</p>
                    <p>{value}</p>
                  </div>
                ))}
            </div>
          )}

        {recommendations?.length > 0 && (
          <div className="product-recommendations">
            <p className="section-title">Recomendaciones:</p>
            <div className="recommendations-carousel">
              {recommendations.map((item, index) => (
                <div key={index} className="recommendation-item">
                  <AmazonProductCard
                    key={index}
                    productDefault={item}
                    trips={trips}
                    drivers={drivers}
                    productHistory={productHistory}
                    categoryWeights={categoryWeights}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {product?.customer_reviews?.length > 0 && (
          <div className="product-reviews">
            <p className="section-title">Reseñas principales</p>
            {product?.customer_reviews?.map((item, index) => (
              <div key={index} className="product-review-item">
                <div className="product-review-header">
                  <img
                    src={`https://eu.ui-avatars.com/api/?name=${item.customer_name}&size=250`}
                    alt={`${item.customer_name}'s profile`}
                    className="profile-image"
                  />
                  <span className="product-review-user">
                    {item.customer_name}
                  </span>
                </div>
                <div className="product-review-rating">
                  <StarRating rating={item.rating.split(' ')[0]} />
                  <p style={{ fontWeight: 'bold' }}>{item.review_title}</p>
                </div>
                <p className="product-review-date">{item.date}</p>
                <p className="product-review-body">{item.review_snippet}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: product?.optimized_url ?? '',
          title: product?.title ?? '',
          image: product?.image ?? '',
        }}
      />
    </div>
  );
}
