import './CheckoutPage.css';
import React, { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {
  query,
  getDocs,
  collection,
  where,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import { Button } from 'reactstrap';
import { MdChevronLeft } from 'react-icons/md';
import { formatDateISO, parseDate } from '../../../util/date';
import { AmazonCheckoutCard } from '../../../components/Products/Card/Amazon/AmazonCheckoutCard';
import { validateCardNumber } from '../../../util/card';
import { calculateWeight } from '../../../util/amazon';
import { orderByPrice } from '../../../util/array';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { useAuth } from '../../../hooks/useAuth';
import { cancelAllRequests } from '../../../util/axios-req';
import CreateDeliveryAddressModal from '../../../components/Modals/CreateDeliveryAddressModal';
import PayByCardModal from '../../../components/Modals/PayByCardModal';
import BankAccountAddress from '../../../components/Checkout/BankAccountAddress/BankAccountAddress';
import CardOptionSelector from '../../../components/Checkout/CardOptionSelector/CardOptionSelector';
import DiscountCodeSection from '../../../components/Checkout/DiscountCodeSection/DiscountCodeSection';
import DeliveryAddresses from '../../../components/Checkout/DeliveryAddress/DeliveryAddress';
import { SheinCheckoutCard } from '../../../components/Products/Card/Shein/SheinCheckoutCard';
import { removeItemFromCart } from '../../../util/cart';
import { getItemIdKey } from '../../../util/sources';
import CreateNoteModal from '../../../components/Modals/CreateNoteModal';
import PaymentService from '../../../services/payment.service';
import EmailService from '../../../services/email.service';
import FirebaseService from '../../../services/firebase.service';
import { DEFAULT_AMAZON_SHIPPING, DEFAULT_SHIPPING } from '../../../contants';
import { LoyaltySection } from '../../../components/Checkout/LoyaltySection/LoyaltySection';

export function CheckoutPage() {
  const [loading, setLoading] = useState(true);
  const [loadingSession, setLoadingSession] = useState(true);
  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingPaymentModal, setLoadingPaymentModal] = useState(false);
  const [loadingCreateAdressModal, setLoadingCreateAdressModal] =
    useState(false);

  const isAnyLoading =
    loading ||
    loadingSession ||
    loadingCode ||
    loadingPaymentModal ||
    loadingCreateAdressModal;

  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const [readyForPay, setReadyForPay] = useState(false);
  const [showErrorPay, setShowErrorPay] = useState(false);
  const [showErrorPayment, setShowErrorPayment] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [cardCvc, setCardCvc] = useState('');
  const [infoPayment, setInfoPayment] = useState(null);
  const [activePaymentOption, setActivePaymentOption] = useState(1);
  const [activePaymentPlan, setActivePaymentPlan] = useState(1);

  const [activeDeliveryOption, setActiveDeliveryOption] = useState(1);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [newDeliveryAddressStreetAddress, setNewDeliveryAddressStreetAddress] =
    useState();
  const [newDeliveryAddressCity, setNewDeliveryAddressCity] = useState();
  const [newDeliveryAddressCountry, setNewDeliveryAddressCountry] = useState();

  const [
    otherDeliveryAddressesAreVisible,
    setOtherDeliveryAddressesAreVisible,
  ] = useState(false);
  const [commentModalIsVisible, setCommentModalIsVisible] = useState(false);
  const [commentModalData, setCommentModalData] = useState(null);
  const [
    createDeliveryAddressModalIsVisible,
    setCreateDeliveryAddressModalIsVisible,
  ] = useState(false);

  const [infoDiscount, setInfoDiscount] = useState(null);
  const [discountCode, setDiscountCode] = useState('');

  const [pointsToUse, setPointsToUse] = useState(0);

  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);

  const navigate = useNavigate();
  const { profile, cart, setCart, setCheckout } = useAuth();
  const paymentService = PaymentService.getInstance();
  const emailService = EmailService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  const DELIVERY_OPTIONS = [
    {
      id: 1,
      title: 'En sucursal',
      description: '1a Calle Pte. 3028, San Salvador, El Salvador.',
    },
    {
      id: 2,
      title: 'A domicilio',
      description:
        'Entregas a San Salvador el mismo día, otros departamentos el siguiente día habil.',
      disabled: !profile,
    },
  ];

  const PAYMENT_METHODS_OPTIONS = [
    { id: 1, title: 'Transferencia' },
    { id: 2, title: 'Tarjeta' },
  ];

  const PAYMENT_PLANS_OPTIONS = [
    {
      id: 1,
      title: 'Completo',
      description: 'Paga el total de tu pedido al confirmar tu compra.',
    },
    {
      id: 2,
      title: 'Anticipo',
      description:
        'Paga solo el 25% de tu pedido al confirmar tu compra y el resto al recibirlo.',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      cancelAllRequests();

      const driversPromise = searchDrivers();
      const tripsPromise = searchTrips();

      await Promise.all([driversPromise, tripsPromise]);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (cart?.items?.length === 0 || isAnyLoading || isPaymentProcessed) return;
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const paramsObject = {};
    for (const [key, value] of params.entries()) paramsObject[key] = value;
    if (Object.keys(paramsObject).length === 0) return;
    if (
      process.env.NODE_ENV === 'development' ||
      paramsObject.esAprobada === 'True'
    )
      pay(paramsObject, 'card');
    else {
      CustomToast('error', 'Pago no aprobado');
      setInfoPayment(paramsObject);
      setShowErrorPayment(true);
      setIsPaymentProcessed(true);
    }
  }, [window.location.href, cart, isAnyLoading, isPaymentProcessed]);

  useEffect(() => {
    fetchMyDeliveryAddresses();
  }, [profile]);

  useEffect(() => {
    const deliveryCache = JSON.parse(sessionStorage.getItem('delivery-option'));
    const paymentOptionCache = JSON.parse(
      sessionStorage.getItem('payment-option'),
    );
    const paymentPlanCache = JSON.parse(sessionStorage.getItem('payment-plan'));
    const pointsToUseCache = JSON.parse(
      sessionStorage.getItem('points-to-use'),
    );
    if (deliveryCache && profile)
      setActiveDeliveryOption(parseInt(deliveryCache));
    if (paymentOptionCache)
      setActivePaymentOption(parseInt(paymentOptionCache));
    if (paymentPlanCache) setActivePaymentPlan(parseInt(paymentPlanCache));
    if (pointsToUseCache) setPointsToUse(parseInt(pointsToUseCache));
    setLoadingSession(false);
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (
      !params.get('idTransaccion') &&
      !readyForPay &&
      trips.length > 0 &&
      drivers.length > 0 &&
      cart?.items
    )
      updateDeliveryDates(cart.items);
  }, [cart, trips, drivers, readyForPay]);

  useEffect(() => {
    const storedDiscountCode = JSON.parse(
      localStorage.getItem('discount-code'),
    );
    if (storedDiscountCode) {
      setDiscountCode(storedDiscountCode);
      verifyDiscountCode(storedDiscountCode);
    }
  }, [cart]);

  const verifyDiscountCode = async (code) => {
    if (!code) return;
    try {
      setLoadingCode(true);
      const discountCodes = await firebaseService.findDiscountCodes();
      const findCode = discountCodes.find((value) => value.code === code);
      let myOrders = null;
      if (profile) myOrders = await firebaseService.findMyOrders(profile?.uid);
      if (findCode?.status) {
        if (
          findCode?.singleUse &&
          myOrders?.some((order) => order?.codeDiscount?.code === code)
        )
          throw new Error('Código ya utilizado');
        if (
          parseFloat(findCode?.minAmount).toFixed(2) >
          parseFloat(totalProducts).toFixed(2)
        )
          throw new Error(
            'Monto mínimo para aplicar el código: $' + findCode?.minAmount,
          );
        localStorage.setItem('discount-code', JSON.stringify(code));
        setInfoDiscount(findCode);
      } else throw new Error('Código no válido');
    } catch {
      CustomToast('error', 'Código de descuento no válido');
      localStorage.removeItem('discount-code');
      setInfoDiscount(null);
      setDiscountCode('');
    } finally {
      setLoadingCode(false);
    }
  };

  const clearDiscountCode = () => {
    setInfoDiscount(null);
    setDiscountCode('');
    localStorage.removeItem('discount-code');
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      setDrivers(response ?? []);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los viajeros');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      setTrips(response ?? []);
    } catch {
      CustomToast('error', 'No se pudo cargar la información de los viajeros');
    }
  };

  const totalProducts = useMemo(() => {
    if (!cart) return 0;
    return (
      cart.items?.reduce((acc, item) => {
        const quantity = item.quantity ?? 1;
        const price = (item?.priceCalculate ?? 0) * quantity;
        return price + acc;
      }, 0) ?? 0
    );
  }, [cart]);

  const discountOrder = useMemo(() => {
    if (!infoDiscount) return { discount: 0, total: 0 };
    const discountValue = parseFloat(infoDiscount.discount);
    const calculateDiscount = (baseAmount) => {
      let discountAmount = discountValue;
      if (infoDiscount.percentage)
        discountAmount = Number(
          (baseAmount * (discountValue / 100)).toFixed(2),
        );
      if (discountAmount > baseAmount) discountAmount = baseAmount;
      return discountAmount;
    };
    let discount = 0;
    switch (infoDiscount.type) {
      case 'product':
        discount = calculateDiscount(totalProducts);
        break;
      case 'shipping':
        if (activeDeliveryOption !== 2)
          return { discount: 0, total: totalProducts + 2.5 };
        discount = calculateDiscount(2.5);
        break;
      case 'total':
      default:
        discount = calculateDiscount(
          activeDeliveryOption === 2 ? totalProducts + 2.5 : totalProducts,
        );
        break;
    }
    return {
      discount,
      total: totalProducts - discount,
    };
  }, [infoDiscount, totalProducts, activePaymentOption, activeDeliveryOption]);

  const getOrderTotal = () => {
    let newOrderTotal =
      totalProducts - discountOrder.discount - pointsToUse / 100;

    // Add delivery option fee if applicable
    if (activeDeliveryOption === 2) {
      const deliveryCommission = 2.5;
      newOrderTotal += deliveryCommission;
    }

    // Add payment option commission if applicable
    if (activePaymentOption === 2) {
      const paymentCommission = calculatePaymentFees();
      newOrderTotal += paymentCommission;
    }

    // Add advance payment plan commission if applicable
    if (activePaymentPlan === 2) {
      const advanceCommission = calculateAdvanceFees();
      newOrderTotal += advanceCommission;
    }

    return newOrderTotal;
  };

  const getTotalForProduct = (
    productInfo,
    discountFixed = 0,
    loyaltyPointsFixed = 0,
  ) => {
    const totalProductCart = cart?.items?.length ?? 1;
    const quantity = productInfo.quantity ?? 1;
    const priceProduct = productInfo?.priceCalculate ?? 0;
    const subTotal = priceProduct * quantity;

    // Calculate delivery fee per product
    const deliveryHome =
      activeDeliveryOption === 2 ? 2.5 / totalProductCart : 0;

    // Initialize variables
    let discount = 0;
    let commission = 0;
    let total = 0;
    let restDiscount = 0;
    let pointsUsed = 0; // Points used for this product
    let restPoints = 0; // Remaining points not used

    // Apply discount logic
    if (infoDiscount) {
      const discountValue = parseFloat(infoDiscount.discount);
      if (!infoDiscount.percentage) {
        const priceAfterFixedDiscount = subTotal - discountFixed;
        discount = subTotal - priceAfterFixedDiscount;
        restDiscount =
          priceAfterFixedDiscount < 0 ? -priceAfterFixedDiscount : 0;
      } else {
        discount = parseFloat((subTotal * (discountValue / 100)).toFixed(2));
        restDiscount = discountFixed;
      }
    }

    // Apply fixed loyalty points to discount
    if (loyaltyPointsFixed > 0) {
      const priceAfterFixedDiscount = subTotal - pointsToUse / 100;
      discount += subTotal - priceAfterFixedDiscount;
      restPoints = priceAfterFixedDiscount < 0 ? -priceAfterFixedDiscount : 0;
      pointsUsed = (subTotal - priceAfterFixedDiscount) * 100;
    }

    // Calculate commission based on delivery and payment options
    if (
      activePaymentOption === 2 ||
      (activeDeliveryOption === 2 && activePaymentOption !== 1)
    ) {
      commission = (subTotal - discount) * 0.03;
    }

    // Calculate total
    total = subTotal - discount + commission;

    // Add financing plan fees if applicable
    if (activePaymentPlan === 2) {
      const advanceCommission = calculateAdvanceFees();
      const financingPerProduct = advanceCommission / totalProductCart;
      total += financingPerProduct;
    }

    // Ensure total isn't negative
    total = Math.max(total, 0);

    // Return the final values as floats, including restPoints and pointsUsed
    return {
      total: parseFloat(total.toFixed(2)),
      priceProduct: parseFloat(priceProduct.toFixed(2)),
      deliveryHome: parseFloat(deliveryHome.toFixed(2)),
      discount: parseFloat(discount.toFixed(2)),
      commission: parseFloat(commission.toFixed(2)),
      restDiscount: parseFloat(restDiscount.toFixed(2)),
      pointsUsed: parseFloat(pointsUsed.toFixed(2)),
      restPoints: parseFloat(restPoints.toFixed(2)),
    };
  };

  const calculatePaymentFees = () => {
    const baseTotal = totalProducts - (discountOrder?.discount ?? 0);
    if (activeDeliveryOption === 2) return (baseTotal + 2.5) * 0.03;
    return baseTotal * 0.03;
  };

  const calculateAdvanceFees = () => {
    return (totalProducts - (discountOrder?.discount ?? 0)) * 0.03;
  };

  const getAdvancePayment = (payload) => {
    const total = payload ?? parseFloat(getOrderTotal().toFixed(2));
    return total * 0.25;
  };

  const toggleCreateDeliveryAddressModal = () => {
    setCreateDeliveryAddressModalIsVisible(
      !createDeliveryAddressModalIsVisible,
    );
  };

  const toggleOtherDeliveryAddresses = () => {
    setOtherDeliveryAddressesAreVisible(!otherDeliveryAddressesAreVisible);
  };

  const toggleCommentModal = (item) => {
    setCommentModalIsVisible((prev) => {
      setCommentModalData(prev ? null : item);
      return !prev;
    });
  };

  const togglePayByCard = () => {
    setShowErrorPay(false);
    const total = parseFloat(getOrderTotal().toFixed(2));
    if (total < 5) {
      setShowErrorPay(true);
      return;
    }
    setPayModal(!payModal);
  };

  const isValidateAddress = useMemo(() => {
    if (activeDeliveryOption === 1) return true;
    const find = deliveryAddresses.find((value) => value?.isActive);
    if (!find) return false;
    return true;
  }, [activeDeliveryOption, deliveryAddresses]);

  const validateCardData = () => {
    if (cardCvc.length < 3) {
      CustomToast('warning', 'Ingresa un cvc valido');
      return false;
    }
    const month = parseInt(cardExpiryMonth, 10);
    if (cardExpiryMonth.length < 2 || month < 1 || month > 12) {
      CustomToast('warning', 'Ingresa un mes valido');
      return false;
    }
    const currentYear = new Date().getFullYear();
    if (
      cardExpiryYear.length < 4 ||
      parseInt(cardExpiryYear, 10) < currentYear
    ) {
      CustomToast('warning', 'Ingresa un año valido');
      return false;
    }
    const isCardNumberValid = validateCardNumber(cardNumber);
    if (!isCardNumberValid) {
      CustomToast('warning', 'Ingresa un numero de tarjeta valido');
      return false;
    }
    return true;
  };

  const generatePay = async () => {
    if (!profile) return;
    if (!validateCardData()) return;
    setLoadingPaymentModal(true);
    const payload = {
      tarjetaCreditoDebido: {
        numeroTarjeta: cardNumber,
        cvv: cardCvc,
        mesVencimiento: parseFloat(cardExpiryMonth),
        anioVencimiento: parseFloat(cardExpiryYear),
      },
      monto:
        activePaymentPlan === 2
          ? parseFloat(getAdvancePayment().toFixed(2))
          : parseFloat(getOrderTotal().toFixed(2)),
      urlRedirect: window.location.href,
      nombre: profile.firstName,
      apellido: profile.lastName,
      email: profile.email,
      telefono: profile.phone,
    };
    await paymentService.create3ds(payload).then((response) => {
      if (response?.data?.urlCompletarPago3Ds)
        window.location.href = response?.data?.urlCompletarPago3Ds;
    });
  };

  const pay = async (payment, mode = 'card') => {
    try {
      const sortProducts = orderByPrice(cart?.items ?? []);
      let discountFixed = parseFloat(infoDiscount?.discount ?? 0);
      let loyaltyPointsFixed = parseInt(pointsToUse);
      const totalFinancingAmount =
        activePaymentPlan === 2
          ? parseFloat(calculateAdvanceFees().toFixed(2))
          : 0;
      const numberOfProducts = sortProducts.length;
      const financingPerProduct = totalFinancingAmount / numberOfProducts;
      const payloadsOrder = await Promise.all(
        sortProducts.map(async (item) => {
          let product = null;
          let weight = 0;
          switch (item?.source) {
            case 'amazon':
              product = {
                nameProduct: item?.title ?? '',
                asin: item?.asin ?? '',
                nameShop: item?.source,
                urlProduct: item?.optimized_url ?? '',
                urlTracking: '',
                comment: item?.comment ?? '',
                urlPhoto: item?.image ?? '',
                uidProduct: '',
                color: item?.color ?? null,
                size: item?.size ?? null,
              };
              weight = calculateWeight(item?.weightAmazon);
              break;
            case 'shein':
              product = {
                nameProduct: item?.goods_name ?? '',
                asin: item?.goods_id ?? '',
                nameShop: item?.source ?? '',
                urlProduct: item?.url ?? '',
                urlTracking: '',
                comment: item?.comment ?? '',
                urlPhoto: item?.goods_img ?? '',
                uidProduct: '',
                color: item?.color ?? null,
                size: item?.size ?? null,
              };
              weight = 1;
              break;
            default:
              weight = 1;
              break;
          }
          let infoAddress = {
            country: 'El Salvador',
            address: '1a Calle Pte. 3028, San Salvador, El Salvador',
          };
          if (activeDeliveryOption === 2) {
            const findAddress = deliveryAddresses?.find(
              (value) => value?.isActive,
            );
            if (findAddress)
              infoAddress = {
                address: `${findAddress.streetAddress}, ${findAddress.city}, ${findAddress.country}`,
                country: findAddress.country,
              };
          }
          let dateDeliveryEstimate = item?.estimateDeliveryDate ?? '';
          if (item?.quantity >= 6) dateDeliveryEstimate = '';
          const totalProduct = getTotalForProduct(
            item,
            discountFixed,
            loyaltyPointsFixed,
          );
          const newOrder = {
            uid: crypto.randomUUID(),
            customerName: `${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`,
            customerUid: profile?.uid ?? '',
            dateCreated: new Date().toISOString(),
            arrivalDate: dateDeliveryEstimate,
            deliveryAddress: infoAddress,
            isDelivery: true,
            traveler: {
              name: item?.travelerSelect?.travelerName ?? '',
              dateTraveled: item?.travelerSelect?.date ?? '',
              uidTrip: item?.travelerSelect?.uid ?? '',
            },
            status: 'Orden Confirmada',
            weigthEstimate: weight,
            isWeigthEstimate: true,
            product,
            productNumber: item?.quantity ?? 1,
            financing: financingPerProduct.toFixed(2) ?? 0,
            priceProduct: totalProduct?.priceProduct,
            priceDeliveryHome: totalProduct?.deliveryHome,
            commission: totalProduct?.commission,
            discount: totalProduct?.discount,
            total: totalProduct?.total,
            canceledAmout:
              mode === 'card'
                ? activePaymentPlan === 2
                  ? parseFloat(
                      getAdvancePayment(totalProduct?.total).toFixed(2),
                    )
                  : parseFloat(totalProduct?.total)
                : 0,
            infoPayment: payment ?? false,
            pendingCheck: mode === 'card' ? false : true,
            codeDiscount: discountFixed === 0 ? false : (infoDiscount ?? false),
            loyaltyPoints: totalProduct?.pointsUsed,
          };
          discountFixed = totalProduct?.restDiscount;
          loyaltyPointsFixed = totalProduct?.restPoints;
          const updateData = {};
          if (newOrder?.canceledAmout > 0 || newOrder?.loyaltyPoints > 0)
            updateData.loyaltyPoints =
              (parseInt(profile?.loyaltyPoints) ?? 0) -
              (parseInt(newOrder.loyaltyPoints) ?? 0) +
              (parseInt(newOrder?.canceledAmout) ?? 0);
          if (newOrder?.loyaltyPoints > 0)
            updateData.usedLoyaltyPoints =
              (parseInt(profile?.usedLoyaltyPoints) ?? 0) +
              (parseInt(newOrder.loyaltyPoints) ?? 0);
          if (Object.keys(updateData).length > 0)
            await firebaseService.updateUser(profile?.uid, updateData);
          return newOrder;
        }) ?? [],
      );
      const promises = [];
      payloadsOrder.forEach(async (payload) => {
        const resOrder = await addDoc(collection(db, 'Orders'), payload);
        if (!resOrder) return;
        const findAddress = deliveryAddresses?.find((value) => value?.isActive);
        const payloadEmail = {
          to: profile?.email ?? '',
          template: 'order_created',
          context: {
            id: resOrder.id,
            date: formatDateISO(new Date()),
            status: {
              trackingUrl: `https://passeioapp.com/orders/${resOrder.id}`,
            },
            shipping: {
              name: `${profile?.firstName ?? ''}`,
              address:
                activeDeliveryOption === 2
                  ? findAddress?.streetAddress
                  : '1a Calle Pte. 3028',
              city: '',
              state:
                activeDeliveryOption === 2 ? findAddress?.city : 'San Salvador',
              zip: '',
              country:
                activeDeliveryOption === 2
                  ? findAddress?.country
                  : 'El Salvador',
            },
            item: {
              name: payload.product?.nameProduct,
              quantity: payload.productNumber,
              price: parseFloat(Number(payload.total).toFixed(2)),
            },
            payment: {
              subtotal: parseFloat(Number(payload.total).toFixed(2)),
              taxes:
                parseFloat(payload.financing ?? 0) +
                parseFloat(payload.priceDelivery ?? 0) +
                parseFloat(payload.priceDeliveryHome ?? 0),
              total: parseFloat(Number(payload.total).toFixed(2)),
            },
          },
        };
        promises.push(emailService.send(payloadEmail));
      });
      if (pointsToUse > 0) sessionStorage.removeItem('points-to-use');
      await firebaseService.updateCart(profile?.uid, { ...cart, items: [] });
      setCart({ ...cart, items: [] });
      await Promise.all(promises).then(() => {
        CustomToast('success', 'Orden creada con éxito');
      });
    } catch (err) {
      console.error(err);
      CustomToast('error', 'Error al crear la orden');
    } finally {
      setIsPaymentProcessed(true);
      setTimeout(() => {
        navigate('/orders');
      }, 3000);
    }
  };

  const placeOrder = (method) => {
    if (method === 'transfer') {
      const message = 'Hola Passeio, aquí les mando mi comprobante de pago';
      const url = `https://wa.me/50379502151?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
      pay(null, 'transfer');
    }
  };

  const fetchMyDeliveryAddresses = async () => {
    if (!profile) return;
    try {
      let fetchedAddresses = [];
      const addressesRef = query(
        collection(db, 'DeliveryAddresses'),
        where('userUid', '==', profile?.uid),
      );
      const q = query(addressesRef);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newAddress = doc.data();
        fetchedAddresses.push({
          ...newAddress,
          idDoc: doc.id,
        });
      });
      setDeliveryAddresses(fetchedAddresses);
    } catch {
      CustomToast(
        'error',
        'No se pudo cargar la información de las direcciones',
      );
    }
  };

  const createNewDeliveryAddress = async () => {
    if (!profile) return;
    setLoadingCreateAdressModal(true);
    const activeDeliveryAddress = deliveryAddresses.find((obj) => {
      return obj.isActive === true;
    });

    if (activeDeliveryAddress) {
      const addressOld = doc(
        db,
        'DeliveryAddresses',
        activeDeliveryAddress.idDoc,
      );
      await updateDoc(addressOld, {
        isActive: false,
      });
    }
    const newDeliveryAddress = {
      uid: crypto.randomUUID(),
      userUid: profile?.uid,
      streetAddress: newDeliveryAddressStreetAddress,
      city: newDeliveryAddressCity,
      country: newDeliveryAddressCountry,
      isActive: true,
    };
    await addDoc(collection(db, 'DeliveryAddresses'), newDeliveryAddress);
    fetchMyDeliveryAddresses();
    toggleCreateDeliveryAddressModal();
    setNewDeliveryAddressStreetAddress();
    setNewDeliveryAddressCity();
    setNewDeliveryAddressCountry();
    setLoadingCreateAdressModal(false);
  };

  const changeDeliveryAddress = async (address) => {
    const activeDeliveryAddress = deliveryAddresses.find((obj) => {
      return obj.isActive === true;
    });
    try {
      if (activeDeliveryAddress) {
        const addressOld = doc(
          db,
          'DeliveryAddresses',
          activeDeliveryAddress.idDoc,
        );
        await updateDoc(addressOld, {
          isActive: false,
        });
      }
      const addressNew = doc(db, 'DeliveryAddresses', address.idDoc);
      await updateDoc(addressNew, {
        isActive: true,
      });

      fetchMyDeliveryAddresses();
    } catch {
      CustomToast('error', 'No se pudo cambiar la dirección de envío');
    }
  };

  const deleteDeliveryAddress = async (address, e) => {
    e.stopPropagation();

    Swal.fire({
      text: '¿Estas segur@ que quieres eliminar esta direccion de envío?',
      icon: 'warning',
      showCancelButton: true,
      background: '#111111',
      confirmButtonColor: '#FFD000',
      cancelButtonColor: '#555555',
      confirmButtonText: 'Si, segur@',
      cancelButtonText: 'No, cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const getAddress = doc(db, 'DeliveryAddresses', address.idDoc);
          await deleteDoc(getAddress);
          fetchMyDeliveryAddresses();
        } catch {
          CustomToast('error', 'No se pudo eliminar la dirección de envío');
        }
      }
    });
  };

  const removeItem = async (item) => {
    try {
      const items = removeItemFromCart(cart?.items, item);
      if (profile?.uid)
        await firebaseService.updateCart(profile?.uid, { ...cart, items });
      setCart({ ...cart, items });
    } catch {
      CustomToast('error', 'No se pudo eliminar el producto');
    }
  };

  const changeQuantity = (id, quantity) => {
    if (!cart) return;
    const items = cart?.items?.map((value) => {
      const key = getItemIdKey(value?.source);
      if (value?.[key] === id) return { ...value, quantity };
      return value;
    });
    setCart({ ...cart, items });
  };

  const changeComment = (id, comment) => {
    if (!cart) return;
    const items = cart?.items?.map((value) => {
      const key = getItemIdKey(value?.source);
      if (value?.[key] === id) return { ...value, comment };
      return value;
    });
    setCart({ ...cart, items });
  };

  const updateDeliveryDates = async (productsList = []) => {
    const items = productsList.map((product) => {
      if (product?.source === 'amazon') {
        const now = new Date();
        const dateKey =
          product?.prime_shipping_info ?? product?.shipping_info ?? null;
        const dateAmazon = dateKey ? parseDate(dateKey) : null;
        if (!dateAmazon) {
          now.setDate(now.getDate() + DEFAULT_AMAZON_SHIPPING);
          return {
            ...product,
            estimateDeliveryDate: now.toISOString(),
          };
        }
        const filterTrips = trips.filter(
          (trip) => new Date(trip?.date) > dateAmazon,
        );
        const dateOptimization = filterTrips.find((trip) => {
          const driver = drivers.find((user) => user?.uid === trip.travelerUid);
          if (!driver) return false;
          const dayBeforeTravel = parseInt(driver.dayBeforeTravel) ?? 3;
          const travelDate = new Date(trip.date);
          travelDate.setDate(travelDate.getDate() - dayBeforeTravel);
          return dateAmazon < travelDate;
        });
        if (!dateOptimization) {
          const fallbackDate = new Date(dateAmazon);
          fallbackDate.setDate(
            fallbackDate.getDate() + DEFAULT_AMAZON_SHIPPING,
          );
          return {
            ...product,
            estimateDeliveryDate: fallbackDate.toISOString(),
          };
        }
        const dateTravel = new Date(dateOptimization.date);
        const daysAfterTravel =
          parseInt(
            drivers.find((user) => user?.uid === dateOptimization.travelerUid)
              ?.dayAfterTravel,
          ) ?? 3;
        dateTravel.setDate(dateTravel.getDate() + daysAfterTravel);
        return {
          ...product,
          estimateDeliveryDate: dateTravel.toISOString(),
          travelerSelect: dateOptimization,
        };
      } else {
        const now = new Date();
        now.setDate(now.getDate() + DEFAULT_SHIPPING);
        return {
          ...product,
          estimateDeliveryDate: now.toISOString(),
        };
      }
    });
    setCart({ ...cart, items });
    setReadyForPay(true);
  };

  const checkoutCardComponents = {
    amazon: (item, index) => (
      <AmazonCheckoutCard
        key={index}
        product={item}
        removeItemFromCart={removeItem}
        changeNumber={changeQuantity}
        toggleCommentModal={toggleCommentModal}
      />
    ),
    shein: (item, index) => (
      <SheinCheckoutCard
        key={index}
        product={item}
        removeItemFromCart={removeItem}
        changeNumber={changeQuantity}
        toggleCommentModal={toggleCommentModal}
      />
    ),
  };

  if (isAnyLoading) return <PageLoader />;

  return (
    <div id="cartPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Tu carrito
      </h2>
      <div id="cartPageContainer">
        <div id="checkoutDetailsContainer">
          <div>
            {activeDeliveryOption === 2 ? (
              <div>
                <p className="cardHeader">
                  <MdChevronLeft
                    onClick={() => {
                      setActiveDeliveryOption(1);
                      sessionStorage.setItem(
                        'delivery-option',
                        JSON.stringify(1),
                      );
                    }}
                    style={{
                      color: '#FFD000',
                      width: 30,
                      height: 30,
                      marginTop: -2.5,
                      cursor: 'pointer',
                    }}
                  />
                  A domicilio
                </p>
                <div>
                  <Button
                    onClick={toggleCreateDeliveryAddressModal}
                    className="primaryBtn"
                    style={{ marginBottom: '1rem' }}
                  >
                    Crea una dirección de envío nueva
                  </Button>
                  <DeliveryAddresses
                    deliveryAddresses={deliveryAddresses}
                    otherDeliveryAddressesAreVisible={
                      otherDeliveryAddressesAreVisible
                    }
                    toggleOtherDeliveryAddresses={toggleOtherDeliveryAddresses}
                    changeDeliveryAddress={changeDeliveryAddress}
                    deleteDeliveryAddress={deleteDeliveryAddress}
                  />
                  <CreateDeliveryAddressModal
                    isOpen={createDeliveryAddressModalIsVisible}
                    toggle={toggleCreateDeliveryAddressModal}
                    streetAddress={newDeliveryAddressStreetAddress}
                    setStreetAddress={setNewDeliveryAddressStreetAddress}
                    city={newDeliveryAddressCity}
                    setCity={setNewDeliveryAddressCity}
                    country={newDeliveryAddressCountry}
                    setCountry={setNewDeliveryAddressCountry}
                    createNewDeliveryAddress={createNewDeliveryAddress}
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{
                    borderBottom: '1px solid #222222',
                    marginBottom: '1rem',
                  }}
                />
                <div>
                  <p className="cardHeader">Opciones de Entrega</p>
                  <CardOptionSelector
                    options={DELIVERY_OPTIONS}
                    activeOption={activeDeliveryOption}
                    setActiveOption={setActiveDeliveryOption}
                    sessionKey="delivery-option"
                    disabledOptionCheck={(id) => id === 2 && !profile}
                    toastMessage="Debes iniciar sesión para agregar una dirección de envío"
                  />
                </div>
              </>
            )}

            <div
              style={{
                borderBottom: '1px solid #222222',
                marginBottom: '1rem',
              }}
            />
            <div>
              <p className="cardHeader">Métodos de Pago</p>
              <CardOptionSelector
                options={PAYMENT_METHODS_OPTIONS}
                activeOption={activePaymentOption}
                setActiveOption={setActivePaymentOption}
                sessionKey="payment-option"
              />
            </div>

            {activePaymentOption === 1 && (
              <div
                id="bankAccountAddressesContainer"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <BankAccountAddress
                  bankName="BAC"
                  accountType="Cuenta corriente"
                  accountNumber="#201419827"
                  accountName="PASSEIO APP"
                  email="info@passeioapp.com"
                />
                <BankAccountAddress
                  bankName="Banco Agricola"
                  accountType="Cuenta de ahorro"
                  accountNumber="#3550551200"
                  accountName="PASSEIO APP"
                  email="info@passeioapp.com"
                />
              </div>
            )}
          </div>

          <div
            style={{ borderBottom: '1px solid #222222', marginBottom: '1rem' }}
          />
          <div>
            <p className="cardHeader">Plan de pago</p>
            <CardOptionSelector
              options={PAYMENT_PLANS_OPTIONS}
              activeOption={activePaymentPlan}
              setActiveOption={setActivePaymentPlan}
              sessionKey="payment-plan"
            />
          </div>

          {showErrorPayment && (
            <div className="alert alert-warning" role="alert">
              {infoPayment?.mensaje}
            </div>
          )}
          <div
            style={{ borderBottom: '1px solid #222222', marginBottom: '1rem' }}
          ></div>
          <div>
            <p className="cardHeader">Productos</p>
            {cart?.items?.length > 0 ? (
              cart?.items?.map((item, index) => {
                return checkoutCardComponents[item.source]
                  ? checkoutCardComponents[item.source](item, index)
                  : null;
              })
            ) : (
              <p style={{ textAlign: 'center' }}>
                No hay productos en tu carrito
              </p>
            )}
          </div>
        </div>

        <div id="breakdownContainer">
          <div id="breakdownDetails">
            <p className="cardHeader">Resumen</p>
            <div className="flexRowSpaceBetween">
              <p>Productos (Precio todo incluido):</p>
              <p>
                $
                {(totalProducts + calculatePaymentFees()).toFixed(2) > 0
                  ? (totalProducts + calculatePaymentFees()).toFixed(2)
                  : 0}
              </p>
            </div>
            {activeDeliveryOption === 2 ? (
              <div className="flexRowSpaceBetween">
                <p>Envío a Domicilio:</p>
                <p>$2.50</p>
              </div>
            ) : null}

            {activePaymentOption === 2 ? (
              <div className="flexRowSpaceBetween">
                <p>Procesamiento de pago:</p>
                <p>
                  $
                  {calculatePaymentFees().toFixed(2) > 0
                    ? calculatePaymentFees().toFixed(2)
                    : 0}
                </p>
              </div>
            ) : (
              <div className="flexRowSpaceBetween">
                <p>Procesamiento de pago:</p>
                <p>GRATIS</p>
              </div>
            )}

            {getOrderTotal().toFixed(2) > 0 && activePaymentPlan !== 2 && (
              <div className="flexRowSpaceBetween">
                <p>Descuento por pago completo:</p>
                <p>
                  -$
                  {calculatePaymentFees().toFixed(2) > 0
                    ? calculatePaymentFees().toFixed(2)
                    : 0}
                </p>
              </div>
            )}

            {pointsToUse > 0 && (
              <div className="flexRowSpaceBetween">
                <p>Descuento (Puntos de lealtad):</p>
                <p>-${pointsToUse / 100}</p>
              </div>
            )}

            <div className="flexRowSpaceBetween">
              <p className="cardHeader" style={{ color: '#FFD000' }}>
                Total:
              </p>
              <p style={{ color: '#FFD000' }}>
                $
                {getOrderTotal().toFixed(2) > 0
                  ? getOrderTotal().toFixed(2)
                  : 0}
              </p>
            </div>

            {activePaymentPlan === 2 && (
              <div className="flexRowSpaceBetween">
                <p
                  className="cardHeader"
                  style={{ color: '#FFD000', fontSize: '1rem' }}
                >
                  Cancelar ahora:
                </p>
                <p style={{ color: '#FFD000', fontSize: '1rem' }}>
                  $
                  {getAdvancePayment().toFixed(2) > 0
                    ? getAdvancePayment().toFixed(2)
                    : 0}
                </p>
              </div>
            )}

            <Button
              onClick={() => {
                if (!profile) {
                  navigate('/login');
                  setCheckout(true);
                } else
                  activePaymentOption === 1
                    ? placeOrder('transfer')
                    : togglePayByCard();
              }}
              className="primaryBtn"
              disabled={
                profile &&
                (isAnyLoading ||
                  !(cart?.items?.length > 0) ||
                  !readyForPay ||
                  !isValidateAddress)
              }
            >
              {!profile
                ? 'Por favor inicia sesión para continuar'
                : !(cart?.items?.length > 0)
                  ? 'El carrito está vacío'
                  : !isValidateAddress
                    ? 'Dirección no válida'
                    : !readyForPay
                      ? 'Procesando pedido'
                      : 'Pagar'}
            </Button>
            {showErrorPay && (
              <p style={{ marginTop: '10px', marginBottom: '0' }}>
                El Monto para procesar el pedido debe ser mayor a $5
              </p>
            )}
          </div>
          <DiscountCodeSection
            infoDiscount={infoDiscount}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            loadingCode={loadingCode}
            verifyDiscountCode={verifyDiscountCode}
            clearDiscountCode={clearDiscountCode}
            activeDeliveryOption={activeDeliveryOption}
            discountOrder={discountOrder}
          />
          <LoyaltySection
            loyaltyPoints={profile?.loyaltyPoints ?? 0}
            pointsToUse={pointsToUse}
            setPointsToUse={setPointsToUse}
          />
        </div>
      </div>
      <PayByCardModal
        isOpen={payModal}
        toggle={togglePayByCard}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
        cardExpiryMonth={cardExpiryMonth}
        setCardExpiryMonth={setCardExpiryMonth}
        cardExpiryYear={cardExpiryYear}
        setCardExpiryYear={setCardExpiryYear}
        cardCvc={cardCvc}
        setCardCvc={setCardCvc}
        generatePay={generatePay}
        loading={loading}
        loadingPaymentModal={loadingPaymentModal}
      />
      <CreateNoteModal
        isOpen={commentModalIsVisible}
        toggle={toggleCommentModal}
        value={commentModalData}
        changeComment={changeComment}
      />
    </div>
  );
}
