import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';
import ReactPaginate from 'react-paginate';

export default function AdminUsersPage({ listUser }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(listUser);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = listUser.filter(
        (user) =>
          user?.firstName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          user?.lastName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          user?.phone?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()),
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(listUser);
    }
  }, [searchQuery, listUser]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(
    indexOfFirstItem,
    indexOfFirstItem + itemsPerPage,
  );

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          onChange={(value) => setSearchQuery(value.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Teléfono</th>
            <th>Correo</th>
            <th>Puntos de Lealtad</th>
            <th>Puntos de Lealtad Utilizados</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} onClick={() => navigate(`/user/${item?.uid}`)}>
              <td data-title="ID">{item?.uid || 'N/A'}</td>
              <td data-title="Nombre">
                {`${item?.firstName} ${item?.lastName}`}
              </td>
              <td data-title="Teléfono">{item?.phone}</td>
              <td data-title="Correo">{item?.email}</td>
              <td data-title="Puntos de Lealtad">{item?.loyaltyPoints ?? 0}</td>
              <td data-title="Puntos de Lealtad Utilizados">
                {item?.usedLoyaltyPoints ?? 0}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(filteredUsers.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
}
